/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCaseInfosQuery,
  useGetComplexityQuery,
  useGetOperationNatureListQuery,
  useGetSinappsCauseListQuery,
  useGetSinappsDetailListQuery,
  useGetSinappsSelectListQuery,
  usePostDocumentsMutation,
  usePostSinappsWaterReportMutation,
} from 'store/api'
import {
  isApiError,
  isApiResponse,
} from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  Menu,
  MenuItem,
} from '@mui/material'
import {
  MoreVertRounded,
  TaskRounded,
} from '@mui/icons-material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import { toast } from 'react-toastify'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import LongButton from 'components/LongButton/LongButton'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import NumberField from 'components/FieldWithInputAdornment/NumberField'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type {
  RefDossier,
  CompteRenduRdfSinappsCreatePayload,
  DonneesSinistreDDERequest,
  SinistreToitureRequest,
  InterventionRDfRequest,
  CompteRenduTauxHumidite,
  NatureSinistre,
} from 'API/__generated__/Api'
import {
  CauseSinistre,
  ComplexiteDDE,
  EnumerationsMetier,
  NatureOperation,
  TypeDocument,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const verifyLocationRequired = (locations: string[]) => {
  if (locations.length > 0 && !locations.map((v) => v).includes('Assure')) return true
  if (locations.length > 1) return true
  return false
}

const sinappsWaterReportSchema = Yup.object().shape<Shape<CompteRenduRdfSinappsCreatePayload>>({
  sinistreDDE: Yup.object().shape<Shape<DonneesSinistreDDERequest>>({
    sinistreCorrect: Yup.boolean(),
    detail: Yup.string().nullable(),
    cause: Yup.string().nullable(),
    precisionSinistreDDE: Yup.string().nullable(),
    sinistreToiture: Yup.object().shape<Shape<SinistreToitureRequest>>({
      etatToiture: Yup.string().nullable(),
      typeToiture: Yup.string().nullable(),
      precisionTypeToiture: Yup.string().nullable(),
    }),
  }).required(),
  intervention: Yup.object().shape<Shape<InterventionRDfRequest>>({
    dateIntervention: Yup.string().required('La date est obligatoire'),
    heureDebut: Yup.string().required("L'heure de début est obligatoire"),
    heureFin: Yup.string().required("L'heure de fin est obligatoire"),
    natureOperation: Yup.string().required("La nature de l'opération est obligatoire"),
    complexiteDDE: Yup.mixed<ComplexiteDDE>().required("La compléxité de l'opération est obligatoire"),
    precisionNatureOperation: Yup.string().nullable().when('natureOperation', {
      is: NatureOperation.Destructive,
      then: (schema) => schema.required('La précision est obligatoire si la nature est destructive.'),
    }),
    precisionLocalisationOrigineFuite: Yup.string().nullable().when('localisationOrigineFuite', {
      is: (locations: string[]) => verifyLocationRequired(locations),
      then: (schema) => schema.required('La précision est obligatoire'),
    }),
    localisationOrigineFuite: Yup.array(Yup.string()),
    tauxHumidite: Yup.object().shape<Shape<CompteRenduTauxHumidite>>({
      valeur: Yup.number().required('La valeur est obligatoire'),
      localisationDuReleve: Yup.string().nullable(),
      assechementEngage: Yup.boolean(),
    }).required(),
  }).required(),
  commentaire: Yup.string().required('Le commentaire est obligatoire'),
  conclusion: Yup.string().required('La conclusion est obligatoire'),
  raisonCauseNonIdentifiee: Yup.string().nullable(),
  raisonCauseIdentifieeNonReparee: Yup.string().nullable(),
  precision: Yup.string().nullable(),
}).required()

type NewSinappsWaterReportForm = FormikContextType<CompteRenduRdfSinappsCreatePayload>

/* Styled components -------------------------------------------------------- */
const CardContentContainer = styled.div`
  padding: 0px 20px 20px;
`

const BoldTitle = styled(FormBoldTitle)`
  font-size: 1.15rem;
  margin-bottom: 15px;
`

const CardContainer = styled(Card)`
  margin-top: 20px;
`

const InfoContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`

const FileInfoContainer = styled(InfoContainer)`
  flex-wrap: wrap;
  margin-top: 10px;
`

const FieldContainer = styled.div`
  width: 100%;
`

const DuoButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column-reverse;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;

  div {
    height: 100%;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    grid-template-columns: 1fr;
  }
`

const FileContainer = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.lightgrey};
  border-radius: 4px;
  min-width: 150px;
  height: 40px;
  align-items: center;
`

const FileNameContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-right: auto;
  margin-left: 5px;
`

const EditButton = styled(Button)`
  min-width: 30px;
  width: 30px;
`

/* Component declaration ---------------------------------------------------- */
interface SinappsWaterReportPageProps {}

const SinappsWaterReportPage: React.FC<SinappsWaterReportPageProps> = () => {
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ files, setFiles ] = useState<File[]>([])
  const [ fileIndex, setFileIndex ] = useState<number>(0)
  const [ anchorMenu, setAnchorMenu ] = useState<null | HTMLButtonElement>(null)

  const { currentData: caseInfos } = useGetCaseInfosQuery(caseId)
  const [
    submitNewSinappsWaterReport,
  ] = usePostSinappsWaterReportMutation()
  const [
    submitNewDocument,
  ] = usePostDocumentsMutation()

  const onSubmit = async (values: CompteRenduRdfSinappsCreatePayload, { setSubmitting, resetForm }: FormikHelpers<CompteRenduRdfSinappsCreatePayload>): Promise<void> => {
    const data = structuredClone(values)
    const response = await submitNewSinappsWaterReport({ caseId, data })

    if (isApiResponse<RefDossier>(response)) {
      toast.success('Le compte-rendu à bien été envoyé.')
      if (files[0]) {
        const resfile = await submitNewDocument({ caseId, data: { documentRequests: [ { fichier: files[0], type: TypeDocument.RapportRechercheDeFuite } ]}})
        if (!isApiError(resfile)) {
          setFiles([])
        } else {
          toast.error("Une erreur est survenue lors de l'envoi de la pièce jointe.")
        }
      }
      resetForm()
    } else {
      toast.error("Une erreur est survenue lors de l'envoi du compte-rendu.")
    }
    setSubmitting(false)
  }

  const formikForm: NewSinappsWaterReportForm = useForm<CompteRenduRdfSinappsCreatePayload>(
    {
      initialValues: {
        sinistreDDE: {
          sinistreCorrect: true,
          cause: CauseSinistre.Accident,
          detail: '',
          precisionSinistreDDE: '',
          sinistreToiture: {
            etatToiture: '',
            typeToiture: '',
            precisionTypeToiture: '',
          },
        },
        intervention: {
          dateIntervention: '',
          heureDebut: '',
          heureFin: '',
          natureOperation: NatureOperation.NonDestructive,
          complexiteDDE: ComplexiteDDE.Simple,
          precisionLocalisationOrigineFuite: '',
          precisionNatureOperation: '',
          localisationOrigineFuite: [],
          tauxHumidite: {
            assechementEngage: false,
            localisationDuReleve: '',
            valeur: 0,
          },
        },
        commentaire: '',
        conclusion: '',
        precision: '',
        raisonCauseIdentifieeNonReparee: '',
        raisonCauseNonIdentifiee: '',
      },
      onSubmit: onSubmit,
      validationSchema: sinappsWaterReportSchema,
    },
  )

  const {
    currentData: operationNatureList = [],
    isFetching: isFetchingOperationNatureList,
  } = useGetOperationNatureListQuery()
  const {
    currentData: complexityList = [],
    isFetching: isFetchingComplexityList,
  } = useGetComplexityQuery()
  const {
    currentData: locationReleveList = [],
    isFetching: isFetchingLocationReleveList,
  } = useGetSinappsSelectListQuery({ caseId, listType: EnumerationsMetier.Localisation })
  // const {
  //   currentData: disasterCauseList = [],
  //   isFetching: isFetchingDisasterCauseList,
  // } = useGetSinappsSelectListQuery({ caseId, listType: EnumerationsMetier.CauseSinistre })
  const {
    currentData: disasterCauseList = [],
    isFetching: isFetchingDisasterCauseList,
  } = useGetSinappsCauseListQuery({ caseId, nature: caseInfos?.natureSinistre?.code as NatureSinistre })
  const {
    currentData: detailList = [],
    isFetching: isFetchingDetailList,
  } = useGetSinappsDetailListQuery({ caseId, nature: caseInfos?.natureSinistre?.code as NatureSinistre, cause: formikForm.values.sinistreDDE?.cause as CauseSinistre })
  const {
    currentData: conclusionList = [],
    isFetching: isFetchingConclusionList,
  } = useGetSinappsSelectListQuery({ caseId, listType: EnumerationsMetier.ConclusionCompteRenduRDF })
  const { currentData: locationList = []} = useGetSinappsSelectListQuery({ caseId, listType: EnumerationsMetier.LocalisationOrigineFuite })

  const onLocationClick = (value: string, checked: boolean) => {
    if (checked) {
      formikForm.setFieldValue('intervention.localisationOrigineFuite', [ ...formikForm.values.intervention.localisationOrigineFuite || [], value ])
    } else {
      formikForm.setFieldValue('intervention.localisationOrigineFuite', formikForm.values.intervention.localisationOrigineFuite?.filter((v) => v !== value))
    }
  }

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      setFiles([ ...files, ...Object.values(e.target.files ?? {}) ])
    }
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, index: number): void => {
    setAnchorMenu(event.currentTarget)
    setFileIndex(index)
  }

  const handleMenuClose = () => {
    setAnchorMenu(null)
  }

  const onDeleteFile = () => {
    const newFiles = [ ...files ]
    newFiles.splice(fileIndex, 1)
    setFiles(newFiles)
    handleMenuClose()
  }

  const booleanOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]

  return (
    <Form form={formikForm}>
      <LargeTitle>
        Déposer un compte-rendu RDF
        <DuoButtonContainer>
          <LongButton
            variant="outlined"
            onClick={() => navigate(-1)}
          >
            Annuler
          </LongButton>
          <LongButton
            variant="contained"
            type="submit"
          >
            Valider
          </LongButton>
        </DuoButtonContainer>
      </LargeTitle>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Intervention
          </BoldTitle>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Date d'intervention
              </FormBoldTitle>
              <FormikDatePicker
                name="intervention.dateIntervention"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Heure d'arrivée sur place
              </FormBoldTitle>
              <FormikDatePicker
                time
                name="intervention.heureDebut"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Heure de départ
              </FormBoldTitle>
              <FormikDatePicker
                time
                name="intervention.heureFin"
                size="small"
              />
            </FieldContainer>
          </InfoContainer>
          <FieldContainer>
            <FormBoldTitle
              smaller
              required
            >
              Nature de l'opération de recherche de fuite
            </FormBoldTitle>
            <Field
              component={Select}
              name="intervention.natureOperation"
              displayEmpty
              disabled={isFetchingOperationNatureList}
              size="small"
            >
              <MenuItem value="">
                Sélectionner
              </MenuItem>
              {
                operationNatureList.map((value, index) => (
                  <MenuItem
                    value={value.code || ''}
                    key={`${value.code}-${index}`}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field>
          </FieldContainer>
          {
            formikForm.values.intervention.natureOperation === NatureOperation.Destructive &&
              <FieldContainer>
                <FormBoldTitle
                  smaller
                  required
                >
                  Précision
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Précision de la nature de l'opération"
                  name="intervention.precisionNatureOperation"
                  multiline
                  rows={3}
                  size="small"
                />
              </FieldContainer>
          }
          <FieldContainer>
            <FormBoldTitle
              smaller
              required
            >
              Complexité de la recherche de fuite
            </FormBoldTitle>
            <Field
              component={Select}
              name="intervention.complexiteDDE"
              displayEmpty
              disabled={isFetchingComplexityList}
              size="small"
            >
              <MenuItem value="">
                Sélectionner
              </MenuItem>
              {
                complexityList.map((value, index) => (
                  <MenuItem
                    value={value.code || ''}
                    key={`${value.code}-${index}`}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field>
          </FieldContainer>
          <FieldContainer>
            <FormBoldTitle
              smaller
              required
            >
              Lieu de la recherche
            </FormBoldTitle>
            <Grid>
              {
                locationList.map((value, index) => (
                  <CheckableButton
                    key={`${value.code}-${index}`}
                    checked={formikForm.values.intervention.localisationOrigineFuite?.some((v) => v === value.code) || false}
                    label={value.libelle}
                    onChange={(e, c) => onLocationClick(value.code, c)}
                  />
                ))
              }
            </Grid>
          </FieldContainer>
          <FieldContainer>
            <FormBoldTitle
              smaller
              required={verifyLocationRequired(formikForm.values.intervention.localisationOrigineFuite || [])}
            >
              Précision sur le lieu de recherche
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Précision sur le lieu de recherche"
              name="intervention.precisionLocalisationOrigineFuite"
              multiline
              rows={3}
              size="small"
            />
          </FieldContainer>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Taux maximum relevé en %
              </FormBoldTitle>
              <NumberField
                name="intervention.tauxHumidite.valeur"
                value={formikForm.values.intervention.tauxHumidite?.valeur}
                onChange={(e) => formikForm.setFieldValue('intervention.tauxHumidite.valeur', e.target.value)}
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Localisation du relevé
              </FormBoldTitle>
              <Field
                component={Select}
                name="intervention.tauxHumidite.localisationDuReleve"
                displayEmpty
                disabled={isFetchingLocationReleveList}
                size="small"
              >
                <MenuItem value="">
                  Sélectionner
                </MenuItem>
                {
                  locationReleveList.map((value, index) => (
                    <MenuItem
                      value={value.code || ''}
                      key={`${value.code}-${index}`}
                    >
                      {value.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Assèchement engagé
              </FormBoldTitle>
              <SegmentedButtons
                options={booleanOptions}
                selectedOption={formikForm.values.intervention.tauxHumidite.assechementEngage}
                setSelectedOption={(newVal) => formikForm.setFieldValue('intervention.tauxHumidite.assechementEngage', newVal)}
                smaller
              />
            </FieldContainer>
          </InfoContainer>
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Cause du dégat des eaux
          </BoldTitle>
          {caseInfos?.causeSinistre?.libelle}
          <FieldContainer>
            <FormBoldTitle
              smaller
              required
            >
              Les éléments du sinistre sont-ils corrects ?
            </FormBoldTitle>
            <SegmentedButtons
              options={booleanOptions}
              selectedOption={formikForm.values.sinistreDDE?.sinistreCorrect}
              setSelectedOption={(newVal) => formikForm.setFieldValue('sinistreDDE.sinistreCorrect', newVal)}
              smaller
            />
          </FieldContainer>
          {
            !formikForm.values.sinistreDDE?.sinistreCorrect &&
              <>
                <InfoContainer>
                  <FieldContainer>
                    <FormBoldTitle
                      smaller
                      required
                    >
                      Cause du dégât des eaux
                    </FormBoldTitle>
                    <Field
                      component={Select}
                      name="sinistreDDE.cause"
                      displayEmpty
                      disabled={isFetchingDisasterCauseList}
                      size="small"
                    >
                      <MenuItem value="">
                        Sélectionner
                      </MenuItem>
                      {
                        disasterCauseList.map((value, index) => (
                          <MenuItem
                            value={value.code || ''}
                            key={`${value.code}-${index}`}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                  </FieldContainer>
                  {/* This field can be hidden depending on cause */}
                  {
                    detailList.length &&
                      <FieldContainer>
                        <FormBoldTitle
                          smaller
                          required
                        >
                          Détail du dégât des eaux
                        </FormBoldTitle>
                        <Field
                          component={Select}
                          name="sinistreDDE.detail"
                          displayEmpty
                          disabled={isFetchingDetailList}
                          size="small"
                        >
                          <MenuItem value="">
                            Sélectionner
                          </MenuItem>
                          {
                            detailList.map((value, index) => (
                              <MenuItem
                                value={value.code || ''}
                                key={`${value.code}-${index}`}
                              >
                                {value.libelle}
                              </MenuItem>
                            ))
                          }
                        </Field>
                      </FieldContainer>
                  }
                </InfoContainer>
                <FieldContainer>
                  <FormBoldTitle smaller>
                    Précision
                  </FormBoldTitle>
                  <Field
                    component={TextField}
                    placeholder="Précision sinistre"
                    name="sinistreDDE.precisionSinistreDDE"
                    multiline
                    rows={3}
                    size="small"
                  />
                </FieldContainer>
              </>
          }
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Conclusion et suites
          </BoldTitle>
          <FieldContainer>
            <FormBoldTitle
              smaller
              required
            >
              Conclusion
            </FormBoldTitle>
            <Field
              component={Select}
              name="conclusion"
              displayEmpty
              disabled={isFetchingConclusionList}
              size="small"
            >
              <MenuItem value="">
                Sélectionner
              </MenuItem>
              {
                conclusionList.map((value, index) => (
                  <MenuItem
                    value={value.code || ''}
                    key={`${value.code}-${index}`}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field>
          </FieldContainer>
          <FieldContainer>
            <FormBoldTitle
              smaller
              required
            >
              Commentaire
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Commentaire"
              name="commentaire"
              multiline
              rows={3}
              size="small"
            />
          </FieldContainer>
          <FieldContainer>
            <FormBoldTitle
              smaller
              required
            >
              Ajouter une pièce jointe
            </FormBoldTitle>
            <AttachmentButton
              name="new-mission"
              onChange={handleOnFileChange}
              accept=".pdf"
            >
              <Button
                variant="outlined"
                component="span"
              >
                Choisir un fichier
              </Button>
            </AttachmentButton>
            <FileInfoContainer>
              {
                files.map((file, index) => (
                  <FileContainer key={`${file.name}-${index}`}>
                    <FileNameContainer>
                      <TaskRounded color="primary" />
                      {file.name}
                    </FileNameContainer>
                    <EditButton
                      variant="text"
                      onClick={(e) => handleMenuClick(e, index)}
                    >
                      <MoreVertRounded color="primary" />
                    </EditButton>
                    <Menu
                      anchorEl={anchorMenu}
                      open={Boolean(anchorMenu)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={onDeleteFile}>
                        Supprimer
                      </MenuItem>
                    </Menu>
                  </FileContainer>
                ))
              }
            </FileInfoContainer>
          </FieldContainer>
        </CardContentContainer>
      </CardContainer>
      <Footer />
    </Form>
  )
}

export default SinappsWaterReportPage
