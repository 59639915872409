/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Type imports ------------------------------------------------------------- */
import type { Theme } from '@emotion/react'
import type { ChipColor } from 'types/ChipColor'

/* Internal variables ------------------------------------------------------- */
export const getChipColor = (color: ChipColor, customColor: string, theme: Theme): string => {
  const isValidHexColor = (stringToVerify?: string | null): boolean => {
    const hexColorPattern = /^#([0-9A-Fa-f]{3}){1,2}$/

    if (!stringToVerify || stringToVerify?.length !== 7 || !hexColorPattern.test(stringToVerify)) {
      return false
    }
    return true
  }

  const cleanColor = isValidHexColor(customColor) ? customColor : color

  switch (cleanColor) {
    case 'green':
      return '#DAF4D4'
    case 'orange':
      return '#FBE7D3'
    case 'darkorange':
      return '#ffd48f'
    case 'red':
      return '#e86666'
    case 'salmon':
      return '#FFC0BC'
    case 'pink':
      return '#FBD4F8'
    case 'grey':
      return '#EBEBEB'
    case 'yellow':
      return '#fff9a6'
    case 'purple':
      return '#D7CAFB'
    case 'lightblue':
      return '#D6ECEE'
    case 'blue':
      return '#D4E0FD'
    case 'primary':
      return theme.palette.primary.main
    case 'secondary':
      return theme.palette.secondary.main
    default:
      return cleanColor
  }
}

/* Component declaration ---------------------------------------------------- */
interface ColoredSquareChipProps {
  variant?: 'outlined' | 'filled';
  color: ChipColor;
  customColor?: string;
  textColor?: string;
  bold?: boolean;
  smaller?: boolean;
}

const ColoredSquareChip = styled.div<ColoredSquareChipProps>`
  background: ${(props) => props.variant === 'outlined' ? props.theme.colors.main : getChipColor(props.color, props.customColor || '', props.theme)};
  color: ${(props) => props.variant === 'outlined' ? getChipColor(props.color, props.customColor || '', props.theme) : props.textColor || '#000000'};
  border: ${(props) => props.variant === 'outlined' ? `2px solid ${getChipColor(props.color, props.customColor || '', props.theme)}` : ''};
  font-weight: ${(props) => props.bold ? 'bold' : 'initial'};
  text-align: center;
  align-self: center;
  width: fit-content;
  min-width: 40px;
  height: auto;
  border-radius: ${(props) => props.smaller ? '3px' : '4px'};
  padding: ${(props) => props.smaller ? '0.2rem' : '0.3rem'} 0.4rem;
  font-size: ${(props) => props.smaller ? '.8rem' : '.9rem'};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
`

export default ColoredSquareChip
