/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetArticleBordereauListQuery,
  useGetDiverseBordereauListQuery,
  useGetMeasureUnitListQuery,
  useGetFranchiseQuery,
  useGetLineTypeListQuery,
  useGetRSEQuery,
  useGetRemiseQuery,
  useGetTVARateListQuery,
  useLazyGetQuoteDraftQuery,
  usePostCalculQuoteEndMutation,
  usePostImportQuoteMutation,
  usePostSaveQuoteDraftMutation,
  usePostSaveQuoteMutation,
  useLazyGetInvoiceDraftQuery,
  usePostCalculInvoiceEndMutation,
  usePostImportInvoiceMutation,
  usePostSaveInvoiceDraftMutation,
  usePostSaveInvoiceMutation,
  useGetCaseInfosQuery,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { formatApiErrorMessage } from 'helpers/formatApiErrorMessage'
import { parseNumber } from 'helpers/numberUtils'
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import RouteTitle from 'router/RouteTitle'
import Footer from 'layouts/Footer/Footer'
import CaseWorkflowDownloadButton from 'pages/CaseWorkflowPage/CaseWorkflowComponents/CaseWorflowButtons/CaseWorkflowDownloadButton'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'
import BackButton from 'components/BackButton/BackButton'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import QuoteInvoiceLines from './QuoteInvoiceComponents/QuoteInvoiceLines'
import QuoteInvoiceReadOnlyLines from './QuoteInvoiceComponents/QuoteInvoiceReadOnlyLines'
import QuoteInvoiceTotal from './QuoteInvoiceComponents/QuoteInvoiceTotal'
import QuoteInvoicePrintButton from './QuoteInvoiceComponents/QuoteInvoicePrintButton'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type {
  Devis,
  LigneDevis,
  LigneFacture,
  Facture,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export type Line = LigneDevis & LigneFacture

type QuoteInvoice = Devis & Facture

interface QuoteInvoiceLineRequest extends QuoteInvoice {
  hasLinkedFile: boolean;
  isInvoice: boolean;
}

const quoteInvoiceSchema = Yup.object().shape<Shape<QuoteInvoiceLineRequest>>({
  isInvoice: Yup.boolean(),
  hasLinkedFile: Yup.boolean().when('isInvoice', {
    is: true,
    then: (schema) => schema.isTrue('Il faut lier une facture'),
  }),
  noOrdre: Yup.string().required('Le numéro est obligatoire'),
  commentaire: Yup.string(),
  motifRefus: Yup.string(),
  deductionFranchise: Yup.boolean().required(),
  rse: Yup.boolean().required(),
  remise: Yup.boolean().required(),
  lignes: Yup.array(Yup.object().shape<Shape<Line>>({
    type: Yup.string().required('Le type est obligatoire'),
    unite: Yup.string(),
    codeArticle: Yup.string(),
    libelle: Yup.string(),
    quantite: Yup.number().when('type', {
      is: 'A',
      then: (schema) => schema.min(0.01, 'La quantité doit être supérieure à 0.01'),
    }),
    tva: Yup.string().nullable().when('type', {
      is: 'A',
      then: (schema) => schema.required('La tva est obligatoire'),
    }),
  })),
}).required()

type QuoteInvoiceForm = FormikContextType<QuoteInvoiceLineRequest>

/* Styled components -------------------------------------------------------- */
const TitleButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`

const FirstLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  gap: 10px;
`

const AddButtonContainer = styled(Button)`
  min-width: 200px;
  float: right;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: end;
  margin-top: 10px;
  margin-bottom: 25px;
`

const StatusChips = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const ImportButton = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 10px;
`

interface ChipProps {
  textColor: string;
  backgroundColor: string;
}

const Chip = styled(ColoredSquareChip)<ChipProps>`
  height: 100%;
  min-height: 37px;
  min-width: 160px;
  white-space: pre-line;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  font-weight: bold;
`

/* Component declaration ---------------------------------------------------- */
interface QuoteInvoicePageProps {
  edit?: boolean;
  readOnly?: boolean;
  isInvoice?: boolean;
}

const QuoteInvoicePage: React.FC<QuoteInvoicePageProps> = ({
  edit = false,
  readOnly = false,
  isInvoice = false,
}) => {
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ counter, setCounter ] = useState<number>(1)

  const defaultLine: Line = {
    libelle: '',
    codeArticle: '',
    prixHT: 0,
    prixTTC: 0,
    prixUnitaire: 0,
    quantite: 0,
    tva: null,
    type: 'A',
    unite: '1',
  }

  const { currentData: caseInfos } = useGetCaseInfosQuery(caseId)
  const {
    currentData: lineTypeList = [],
    isFetching: isFetchingLineTypeList,
  } = useGetLineTypeListQuery()
  const {
    currentData: measureUnitList = [],
    isFetching: isFetchingMeasureUnitList,
  } = useGetMeasureUnitListQuery()
  const {
    currentData: tvaRateList = [],
    isFetching: isFetchingTVARateList,
  } = useGetTVARateListQuery()
  const {
    currentData: franchise = 0,
    isFetching: isFetchingFranchise,
  } = useGetFranchiseQuery(caseId)
  const {
    currentData: rse = 0,
    isFetching: isFetchingRse,
  } = useGetRSEQuery(caseId)
  const {
    currentData: remise = 0,
    isFetching: isFetchingRemise,
  } = useGetRemiseQuery(caseId)
  const {
    currentData: articleBordereauList = [],
    isFetching: isFetchingArticleBordereauList,
  } = useGetArticleBordereauListQuery({ dossier: caseId })
  const {
    currentData: diverseBordereauList = [],
    isFetching: isFetchingDiverseBordereauList,
  } = useGetDiverseBordereauListQuery()
  const [
    getQuoteDraft,
    { currentData: quoteDraft },
  ] = useLazyGetQuoteDraftQuery()
  const [
    submitImportQuote,
    { isLoading: isFetchingImportQuote },
  ] = usePostImportQuoteMutation()
  const [
    submitSaveQuoteDraft,
    { isLoading: isSavingQuoteDraft },
  ] = usePostSaveQuoteDraftMutation()
  const [
    submitSaveQuote,
    { isLoading: isSavingQuote },
  ] = usePostSaveQuoteMutation()
  const [
    submitQuoteCalculs,
    { data: quoteCalculs },
  ] = usePostCalculQuoteEndMutation()
  const [
    getInvoiceDraft,
    { currentData: invoiceDraft },
  ] = useLazyGetInvoiceDraftQuery()
  const [
    submitImportInvoice,
    { isLoading: isFetchingImportInvoice },
  ] = usePostImportInvoiceMutation()
  const [
    submitSaveInvoiceDraft,
    { isLoading: isSavingInvoiceDraft },
  ] = usePostSaveInvoiceDraftMutation()
  const [
    submitSaveInvoice,
    { isLoading: isSavingInvoice },
  ] = usePostSaveInvoiceMutation()
  const [
    submitInvoiceCalculs,
    { data: invoiceCalculs },
  ] = usePostCalculInvoiceEndMutation()

  const formikForm: QuoteInvoiceForm = useForm<QuoteInvoiceLineRequest>(
    {
      initialValues: {
        isInvoice,
        hasLinkedFile: false,
        motifRefus: '',
        pied: undefined,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        etat: {},
        sequence: 0,
        commentaire: '',
        deductionFranchise: isInvoice,
        montantFranchise: 0,
        tauxRemise: 0,
        remise: false,
        rse: false,
        tauxRSE: 0,
        lignes: [ { ...defaultLine, type: 'TI' }, defaultLine, { ...defaultLine, type: 'TTI' } ],
        noOrdre: '',
      },
      validationSchema: quoteInvoiceSchema,
    },
  )

  useEffect(() => {
    if (quoteDraft) {
      setCounter(counter + 1)
      formikForm.setValues({
        ...formikForm.values,
        ...quoteDraft,
        lignes: quoteDraft.lignes?.map((value) => value.codeArticle ? ({ ...value, disabled: true }) : value) || [],
        hasLinkedFile: quoteDraft.documentLie?.url ? true : false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        etat: quoteDraft.etat,
      })
    }
  }, [ quoteDraft ])

  useEffect(() => {
    if (invoiceDraft) {
      setCounter(counter + 1)
      formikForm.setValues({
        ...formikForm.values,
        ...invoiceDraft,
        lignes: invoiceDraft.lignes?.map((value) => value.codeArticle ? ({ ...value, disabled: true }) : value) || [],
        hasLinkedFile: invoiceDraft.documentLie?.url ? true : false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        etat: invoiceDraft.etat,
      })
    }
  }, [ invoiceDraft ])

  const fetchDraft = () => {
    isInvoice ? getInvoiceDraft(caseId).catch(console.error) : getQuoteDraft(caseId).catch(console.error)
  }

  const onSaveClick = async () => {
    await (isInvoice ? submitSaveInvoiceDraft : submitSaveQuoteDraft)({
      caseId,
      data: {
        ...formikForm.values,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        etat: formikForm.values.etat,
      },
    }).then((response) => {
      if (isApiError(response)) {
        toast.error(`Une erreur est survenue lors de l'enregistrement du brouillon : ${formatApiErrorMessage(response.error)}.`)
      } else {
        toast.success('Le brouillon a bien été enregistré.')
        navigate(`/dossiers/${caseId}/${isInvoice ? 'facture' : 'devis'}`)
      }
    }).catch(console.error)
  }

  const handleImportPDF = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      await onSaveClick()
      await (isInvoice ? submitImportInvoice : submitImportQuote)({
        caseId,
        data: { Fichier: Object.values(e.target.files || {})[0], Nom: e.target.files[0].name },
      }).then((value) => {
        if (isApiError(value)) {
          toast.error(`L'import du document à échoué : ${formatApiErrorMessage(value)}`)
        } else {
          fetchDraft()
        }
      }).catch(console.error)
    }
  }

  useEffect(() => {
    if (!edit && !readOnly) return
    fetchDraft()
  }, [ edit, readOnly ])

  useEffect(() => {
    if (!isFetchingFranchise && !isFetchingRse && !isFetchingRemise) {
      formikForm.setFieldValue('montantFranchise', franchise)
      formikForm.setFieldValue('tauxRSE', rse)
      formikForm.setFieldValue('tauxRemise', remise)
    }
  }, [ isFetchingFranchise, isFetchingRse, isFetchingRemise ])

  useEffect(() => {
    const lignes = structuredClone(formikForm.values.lignes) || []

    lignes.forEach((line, index) => {
      const result: Line = { ...structuredClone(line), prixHT: 0, prixTTC: 0 }
      let i = index
      if (!result.prixHT) {
        result.prixHT = 0
      }
      if (!result.prixTTC) {
        result.prixTTC = 0
      }

      if (line.type === 'TST') {
        for (i; lignes[i].type !== 'ST' && i > 0; i--) {
          const newLine = lignes[i]

          if (newLine.type === 'A') {
            result.prixHT += parseNumber(newLine.prixHT)
            result.prixHT = parseFloat(result.prixHT.toFixed(2))
            result.prixTTC += parseNumber(newLine.prixTTC)
            result.prixTTC = parseFloat(result.prixTTC.toFixed(2))
          }
        }
        if (lignes[i].type === 'ST') {
          result.libelle = `Sous total - ${lignes[i].libelle}`
          lignes[index] = result
        }
      }
      if (line.type === 'TTI') {
        for (i; lignes[i].type !== 'TI' && i > 0; i--) {
          const newLine = lignes[i]

          if (newLine.type === 'A') {
            result.prixHT += parseNumber(newLine.prixHT)
            result.prixHT = parseFloat(result.prixHT.toFixed(2))
            result.prixTTC += parseNumber(newLine.prixTTC)
            result.prixTTC = parseFloat(result.prixTTC.toFixed(2))
          }
        }
        if (lignes[i].type === 'TI') {
          result.libelle = `Total - ${lignes[i].libelle}`
          lignes[index] = result
        }
      }
      if (line.type === 'A' && !line.tva) {
        const tva = lignes.find((l) => l.type === 'A' && l.tva)?.tva ?? null
        lignes[index].tva = tva
      }
    })

    formikForm.setFieldValue('lignes', lignes)
  }, [ counter ])

  useEffect(() => {
    const lignes = structuredClone(formikForm.values.lignes) || []

    const timer = setTimeout(() => {
      if (isInvoice) {
        submitInvoiceCalculs({ caseId, data: { ...formikForm.values, lignes: lignes?.filter((line) => line.type === 'A') }})
      } else {
        submitQuoteCalculs({ caseId, data: { ...formikForm.values, lignes: lignes?.filter((line) => line.type === 'A') }})
      }
    }, 2000)

    return () => clearTimeout(timer)
  }, [ formikForm.values.lignes ])

  const updateCounter = () => {
    setCounter(counter + 1)
  }

  const addNewLineOrSection = (section: boolean = false) => {
    const lines = structuredClone(formikForm.values.lignes || [])
    const tva = lines.find((line) => line.type === 'A' && line.tva)?.tva ?? null
    let newLines = lines

    if (section) {
      newLines = [
        ...lines,
        { ...defaultLine, type: 'TI' },
        { ...defaultLine, tva },
        { ...defaultLine, type: 'TTI' },
      ]
    } else {
      newLines = [
        ...lines,
        { ...defaultLine, tva },
      ]
    }

    formikForm.setFieldValue('lignes', newLines)
    updateCounter()
  }

  const calculs = useMemo(() => isInvoice? invoiceCalculs : quoteCalculs, [ isInvoice, invoiceCalculs, quoteCalculs ])

  const onSendClick = () => {
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (Object.keys(errors).length === 0) {
          return (isInvoice ? submitSaveInvoice : submitSaveQuote)({ caseId, data: formikForm.values })
        } else {
          console.log(isInvoice ? 'Invoice errors' : 'Quote errors', errors)
        }
      })
      .then((response) => {
        if (isApiError(response)) {
          toast.error(`Une erreur est survenue lors de l'envoi : ${formatApiErrorMessage(response.error)}.`)
        } else if (response) {
          toast.success(isInvoice ? 'La facture a bien été envoyée.' : 'Le devis a bien été envoyé.')
          navigate(`/dossiers/${caseId}`)
        }
      })
      .catch(console.error)
      .finally(() => formikForm.setSubmitting(false))
  }

  const onUpdateSegmentedButtons = (fieldName: 'deductionFranchise' | 'rse' | 'remise', value: boolean) => {
    formikForm.setFieldValue(fieldName, value)
    updateCounter()
  }

  const booleanOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]

  const isLoading = useMemo(() => isFetchingLineTypeList || isFetchingMeasureUnitList || isFetchingTVARateList || isFetchingFranchise || isFetchingRemise || isFetchingRse || isFetchingImportInvoice || isFetchingImportQuote || isFetchingArticleBordereauList || isFetchingDiverseBordereauList, [
    isFetchingLineTypeList,
    isFetchingMeasureUnitList,
    isFetchingTVARateList,
    isFetchingFranchise,
    isFetchingRemise,
    isFetchingRse,
    isFetchingImportInvoice,
    isFetchingImportQuote,
    isFetchingArticleBordereauList,
    isFetchingDiverseBordereauList,
  ])

  return (
    <Form form={formikForm}>
      <RouteTitle title={`${isInvoice ? 'Facture' : 'Devis'} ${caseId}`} />
      <BackButton onClick={() => navigate(`/dossiers/${caseId}`)}>
        Retourner au suivi
      </BackButton>
      <LargeTitle>
        <StatusChips>
          {(edit || readOnly) ? isInvoice ? 'Facture' : 'Devis' : isInvoice ? 'Nouvelle Facture' : 'Nouveau Devis'}
          {
            formikForm.values.statut?.libelle &&
              <Chip
                color="green"
                backgroundColor={formikForm.values.statut.couleurFond || ''}
                textColor={formikForm.values.statut.couleurPolice || ''}
              >
                {formikForm.values?.statut?.libelle}
              </Chip>
          }
          {
            formikForm.values.motifRefus &&
              <ColoredSquareChip color="red">
                {formikForm.values.motifRefus}
              </ColoredSquareChip>
          }
        </StatusChips>
        <TitleButtonContainer>
          {
            formikForm.values.documentLie && formikForm.values.documentLie.url ?
              <CaseWorkflowDownloadButton
                id={formikForm.values.documentLie.id || ''}
                name={getCleanFilename(formikForm.values.documentLie.libelle || '', formikForm.values.documentLie.fileName || '')}
                url={formikForm.values.documentLie.url || ''}
                onDeleteAction={() => {onSaveClick(); fetchDraft()}}
                disable={readOnly}
              /> :
              readOnly ?
                <div /> :
                <ImportButton>
                  <AttachmentButton
                    onChange={handleImportPDF}
                    multiple={false}
                    name="quote-invoice-pdf-attachment"
                  >
                    <Button
                      variant="outlined"
                      component="span"
                      disabled={isFetchingImportInvoice || isFetchingImportQuote}
                    >
                      {isInvoice ? 'Lier une facture' : 'Lier un devis'}
                    </Button>
                  </AttachmentButton>
                  <ErrorMessage name="hasLinkedFile" />
                </ImportButton>
          }
          {
            readOnly && caseInfos &&
              <QuoteInvoicePrintButton
                caseInfos={caseInfos}
                calculs={calculs}
                lines={formikForm.values.lignes || []}
                title={`${isInvoice ? 'Facture' : 'Devis'} N°${formikForm.values.noOrdre}`}
              />
          }
          {
            !readOnly &&
              <React.Fragment>
                <Button
                  variant="outlined"
                  onClick={onSaveClick}
                  disabled={isSavingQuoteDraft || isSavingInvoiceDraft}
                >
                  Enregistrer
                </Button>
                <Button
                  variant="contained"
                  onClick={onSendClick}
                  disabled={isSavingInvoice || isSavingQuote}
                >
                  Envoyer
                </Button>
              </React.Fragment>
          }
        </TitleButtonContainer>
      </LargeTitle>
      {
        isLoading ?
          <CircularProgress /> :
          <React.Fragment>
            <Card>
              <CardContent>
                <FirstLine>
                  <div>
                    <FormBoldTitle>
                      {`Votre numéro de ${isInvoice ? 'facture' : 'devis'} interne`}
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      name="noOrdre"
                      placeholder={`Numéro de ${isInvoice ? 'facture' : 'devis'} interne`}
                      size="small"
                      disabled={readOnly}
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Déduction de la franchise
                    </FormBoldTitle>
                    <SegmentedButtons
                      options={booleanOptions}
                      setSelectedOption={(option) => onUpdateSegmentedButtons('deductionFranchise', option)}
                      selectedOption={formikForm.values.deductionFranchise}
                      smaller
                      disabled={readOnly || !isInvoice}
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Montant franchise
                    </FormBoldTitle>
                    <PriceField
                      name="montantFranchise"
                      size="small"
                      disabled={!formikForm.values.deductionFranchise || readOnly || !isInvoice}
                      onChange={(e) => formikForm.setFieldValue('montantFranchise', e.target.value)}
                    />
                  </div>
                </FirstLine>
                <div>
                  <FormBoldTitle>
                    Commentaire
                  </FormBoldTitle>
                  <Field
                    component={TextField}
                    name="commentaire"
                    placeholder="Commentaire"
                    size="small"
                    disabled={readOnly}
                  />
                </div>
              </CardContent>
            </Card>
            {
              readOnly ?
                <QuoteInvoiceReadOnlyLines
                  lines={formikForm.values.lignes || []}
                  lineTypeList={lineTypeList}
                  measureUnitList={measureUnitList}
                  tvaRateList={tvaRateList}
                /> :
                <QuoteInvoiceLines
                  lines={formikForm.values.lignes || []}
                  setFieldValue={formikForm.setFieldValue}
                  measureUnitList={measureUnitList}
                  lineTypeList={lineTypeList}
                  tvaRateList={tvaRateList}
                  updateCounter={updateCounter}
                  articleBordereauList={articleBordereauList.map(({ libelle, ...rest }) => {return ({ ...rest, libelle: libelle.toLowerCase() })})}
                  diverseBordereauList={diverseBordereauList.map(({ libelle, ...rest }) => {return ({ ...rest, libelle: libelle.toLowerCase() })})}
                />
            }
            {
              !readOnly &&
                <ButtonContainer>
                  <AddButtonContainer
                    variant="contained"
                    onClick={() => addNewLineOrSection(true)}
                  >
                    Ajouter une section
                  </AddButtonContainer>
                  <AddButtonContainer
                    variant="contained"
                    onClick={() => addNewLineOrSection(false)}
                  >
                    Ajouter une ligne
                  </AddButtonContainer>
                </ButtonContainer>
            }
            <QuoteInvoiceTotal calculs={calculs} />
          </React.Fragment>
      }
      <Footer />
    </Form>
  )
}

export default QuoteInvoicePage
